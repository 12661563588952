<div *ngIf="czyZaladowanoDane">
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-2">Ceny live</div>
  </div>

  <div class="container--main d-none d-md-block" *ngIf="czyZaladowanoDane">
    <div class="d-flex py-2">
      <div class="project__menu">
        <a
          [routerLink]=""
          [ngClass]="wyszukiwanie?.filtry?.krypto == 'BTCUSDT' ? 'active' : ''"
          (click)="wybierzKrypto('BTCUSDT')"
          >BTCUSDT</a
        >
        <a
          [routerLink]=""
          [ngClass]="wyszukiwanie?.filtry?.krypto == 'ETHUSDT' ? 'active' : ''"
          (click)="wybierzKrypto('ETHUSDT')"
          >ETHUSDT</a
        >
        <a
          [routerLink]=""
          [ngClass]="wyszukiwanie?.filtry?.krypto == 'ADAUSDT' ? 'active' : ''"
          (click)="wybierzKrypto('ADAUSDT')"
          >ADAUSDT</a
        >
      </div>
    </div>
  </div>

  <div class="container-fluid d-none d-md-block pt-2">
    <div class="box__scroll py-3">
      <div class="tableFixHead tableFixHead--3">
        <table class="table">
          <thead>
            <tr>
              <th>Data</th>
              <th>Cena</th>
              <th>RSI</th>
              <th>EMA</th>
              <th>EMAF</th>
              <th>EMA wartość</th>
              <th>EMAF wartość</th>
              <th>MACD L</th>
              <th>MACD S</th>
              <th>MACD H</th>
              <th>Sygnał</th>
              <!-- <th>Giełda</th> -->
              <th>Konto</th>

              <th>5m</th>
              <th>15m</th>
              <th>1h</th>
              <th>4h</th>
              <th>1d</th>
              <th>3d</th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(ceny)">
            <tr *ngFor="let cena of ceny" class="pointer project-row">
              <td>{{ cena.data | date : "yyyy-MM-dd HH:mm" }}</td>
              <td>{{ cena.cenaOtwarcia | currency }}</td>
              <td>{{ cena.rsi14 | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.ema }}</td>
              <td>{{ cena.emaF }}</td>
              <td>{{ cena.emaWartosc | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.emaFWartosc | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.macdLine | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.macdSignal | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.macdHistogram | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.sygnal }}</td>

              <!-- <td>{{ cena.gielda }}</td> -->
              <td>{{ cena.rodzajKonta }} - {{ cena.czyjeKonto }}</td>

              <td>{{ cena.predykcja5m | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.predykcja15m | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.predykcja1h | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.predykcja4h | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.predykcja1d | number : "1.2-2" : "pl" }}</td>
              <td>{{ cena.predykcja3d | number : "1.2-2" : "pl" }}</td>
            </tr>
          </tbody>
        </table>
        <div
          class="ft__03 text-gray text-center my-5 py-5"
          *ngIf="!czySaWynikiNaLiscie(ceny)"
        >
          <div>Brak cen dla wybranych filtrów</div>
        </div>
      </div>
    </div>
    <div
      class="paginate float-right d-flex align-items-center pt-2 pb-2 pt-lg-2"
    >
      <div
        *ngIf="czySaWynikiNaLiscie(ceny)"
        class="paginate float-right d-flex align-items-center"
      >
        <div>Pokaż wyniki</div>
        <select
          class="paginate__select mx-2"
          name=""
          id=""
          (change)="zmienRozmiarTabeli()"
          [(ngModel)]="rozmiarTabeli"
        >
          <option [ngValue]="100">100</option>
          <option [ngValue]="500">500</option>
          <option [ngValue]="1000">1000</option>
        </select>
        <div class="px-2">
          {{ pobierzNumerRekorduPoczatek() }}-{{
            pobierzNumerRekorduKoniec()
          }}
          z {{ calkowitaLiczbaElementow }}
        </div>
        <div class="d-flex">
          <button
            class="btn__left"
            *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
            (click)="zmienStrone(-1)"
          ></button>
          <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
          <button
            class="btn__right"
            *ngIf="czyJestWiecejRekordow"
            (click)="zmienStrone(1)"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">Ceny live</div>
    </div>

    <div class="d-fledx py-3">
      <div class="project__menu">
        <button class="btn__left"></button>
        <div class="carousel menu_slider" [config]="slideConfig">
          <div class="slide">
            <a
              [routerLink]=""
              [ngClass]="
                wyszukiwanie?.filtry?.krypto == 'BTCUSDT' ? 'active' : ''
              "
              (click)="wybierzKrypto('BTCUSDT')"
              >BTCUSDT</a
            >
          </div>
          <div class="slide">
            <a
              [routerLink]=""
              [ngClass]="
                wyszukiwanie?.filtry?.krypto == 'ETHUSDT' ? 'active' : ''
              "
              (click)="wybierzKrypto('ETHUSDT')"
              >ETHUSDT</a
            >
          </div>
          <div class="slide">
            <a
              [routerLink]=""
              [ngClass]="
                wyszukiwanie?.filtry?.krypto == 'ADAUSDT' ? 'active' : ''
              "
              (click)="wybierzKrypto('ADAUSDT')"
              >ADAUSDT</a
            >
          </div>
        </div>
        <button class="btn__right"></button>
      </div>
    </div>

    <div
      class="box__mobile box__mobile--table mb-3"
      *ngIf="czySaWynikiNaLiscie(ceny)"
    >
      <div class="fixed_table_container">
        <table>
          <tr>
            <th>Data</th>
            <th>Cena</th>
            <th>RSI</th>
            <th>EMA</th>
            <th>EMAF</th>
            <th>EMA wartość</th>
            <th>EMAF wartość</th>
            <th>MACD L</th>
            <th>MACD S</th>
            <th>MACD H</th>
            <th>Sygnał</th>
            <th>Giełda</th>
            <th>Rodzaj konta</th>
            <th>Czyje konto</th>
            <th>5m</th>
            <th>15m</th>
            <th>1h</th>
            <th>4h</th>
            <th>1d</th>
            <th>3d</th>
          </tr>
          <tr *ngFor="let cena of ceny" class="pointer project-row">
            <td>{{ cena.data | date : "yyyy-MM-dd HH:mm" }}</td>
            <td>{{ cena.cenaOtwarcia | currency }}</td>
            <td>{{ cena.rsi14 | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.ema }}</td>
            <td>{{ cena.emaF }}</td>
            <td>{{ cena.emaWartosc | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.emaFWartosc | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.macdLine | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.macdSignal | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.macdHistogram | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.sygnal }}</td>

            <td>{{ cena.gielda }}</td>
            <td>{{ cena.rodzajKonta }}</td>
            <td>{{ cena.czyjeKonto }}</td>

            <td>{{ cena.predykcja5m | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.predykcja15m | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.predykcja1h | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.predykcja4h | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.predykcja1d | number : "1.2-2" : "pl" }}</td>
            <td>{{ cena.predykcja3d | number : "1.2-2" : "pl" }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
      *ngIf="czySaWynikiNaLiscie(ceny)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="100">100</option>
        <option [ngValue]="500">500</option>
        <option [ngValue]="1000">1000</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
    <div
      class="ft__03 text-gray text-center py-5"
      *ngIf="!czySaWynikiNaLiscie(ceny)"
    >
      Brak cen <br />dla wybranych filtrów.
    </div>
  </div>
</div>
