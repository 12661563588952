import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { DodajKonfiguracjeFuturesModel } from 'src/app/modele/futures/dodajKonfiguracjeFuturesModel';
import { DodajKonfiguracjeGieldModel } from 'src/app/modele/futures/dodajKonfiguracjeGieldModel';
import { PobierzKonfiguracjeFuturesRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeFuturesRezultat';
import { PobierzKonfiguracjeGieldFiltry } from 'src/app/modele/futures/pobierzKonfiguracjeGieldFiltry';
import { PobierzKonfiguracjeGieldRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeGieldRezultat';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-dodaj-konfiguracje-futures',
  templateUrl: './dodaj-konfiguracje-futures.component.html',
  styleUrls: ['./dodaj-konfiguracje-futures.component.css'],
})
export class DodajKonfiguracjeFuturesComponent
  extends BazowyComponent
  implements OnInit
{
  czyZaladowanoDane: boolean;
  model: DodajKonfiguracjeFuturesModel = new DodajKonfiguracjeFuturesModel();
  gieldy: Array<PobierzKonfiguracjeGieldRezultat> = [];
  dostepneKrypto: Array<{ value: string; key: string }> = [
    { key: 'ETHUSDT', value: 'ETHUSDT' },
    { key: 'ETHUSDC', value: 'ETHUSDC' },
    { key: 'BTCUSDT', value: 'BTCUSDT' },
    { key: 'ADAUSDT', value: 'ADAUSDT' },
    { key: 'BNBUSDT', value: 'BNBUSDT' },
  ];

  constructor(
    public router: Router,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService,
    modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzGieldy();
    });
  }

  zapisz() {
    this.futuresSerwis
      .dodajKonfiguracjeFutures(this.model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat('Konfiguracja została dodana');
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzGieldy() {
    var model = new RejestrFiltrowanyRequest<PobierzKonfiguracjeGieldFiltry>();
    model.stronicowanie = new Stronicowanie(true);
    model.sortowanie = new Sortowanie('czyjeKonto', KierunekSortowania.asc);
    this.futuresSerwis
      .pobierzKonfiguracjeGield(model, true)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.gieldy = rezultat.listaElementow;
          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }
}
