import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from '../../bazowy.component';
import { ZalogujModel } from 'src/app/modele/logowanie/zalogujModel';
import { LogowanieService } from 'src/app/serwisy/konto/logowanie.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { DaneZalogowanegoUzytkownika } from 'src/app/modele/logowanie/daneZalogowanegoUzytkownika';
import { Ciasteczko } from 'src/app/modele/wspolne/ciasteczka';
import { Jezyk } from 'src/app/modele/wspolne/jezyk';
import { ITradingViewWidget } from 'angular-tradingview-widget';

@Component({
  selector: 'app-logowanie',
  templateUrl: './logowanie.component.html',
})
export class LogowanieComponent extends BazowyComponent implements OnInit {
  public zalogujModel = new ZalogujModel();
  public czyPokazacHaslo = false;
  @ViewChild('tradingview') tradingview?: ElementRef;

  constructor(
    private logowanieSerwis: LogowanieService,
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    private komunikatSerwis: ToastrService,
    public authService: AuthService,
    private _renderer2: Renderer2
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngAfterViewInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
    script.text = `
    {
      "symbol": "CEXIO:MHCUSD",
      "width": 350,
      "colorTheme": "light",
      "isTransparent": true,
      "locale": "en"
    }`;

    this.tradingview?.nativeElement.appendChild(script);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.inicjujJezyk();
    });
  }

  public zaloguj(): void {
    this.logowanieSerwis
      .zalogujPrzezEmail(this.zalogujModel)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.zapiszDaneOLogowaniu(rezultat.element);
          return;
        } else if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
      });
  }

  private zapiszDaneOLogowaniu(dane: DaneZalogowanegoUzytkownika) {
    this.authService.logIn(Ciasteczko.DaneZalogowanegoUzytkownika, dane);
  }

  pokazHaslo() {
    this.czyPokazacHaslo = !this.czyPokazacHaslo;
  }
}
