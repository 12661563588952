<nav class="navbar menu">
  <div class="navbar__container px-md-4">
    <div
      class="navbrand d-flex align-items-center align-items-center justify-content-between"
    >
      <div class="px-4 mx-1 d-md-none"></div>
      <!-- <a class="brand" href="#">
                <img src="assets/img/logo.svg" alt="">
            </a> -->
      <div class="d-none d-md-block menu_desktop">
        <ul class="d-flex mb-0">
          <li class="" [routerLinkActive]="['active']">
            <a class="m1" routerLink="konfiguracje-gield">Giełdy</a>
          </li>
          <li class="" [routerLinkActive]="['active']">
            <a class="m1" routerLink="konfiguracje-futures">Konfiguracje</a>
          </li>
          <li class="" [routerLinkActive]="['active']">
            <a class="m1" routerLink="aktualne-pozycje">Aktualne pozycje</a>
          </li>
          <li class="" [routerLinkActive]="['active']">
            <a class="m1" routerLink="historyczne-pozycje"
              >Historyczne pozycje</a
            >
          </li>
          <li class="" [routerLinkActive]="['active']">
            <a class="m1" routerLink="ceny-live">Ceny live</a>
          </li>
          <li class="" [routerLinkActive]="['active']">
            <a class="m1" routerLink="testy">Testy</a>
          </li>
          <li class="" [routerLinkActive]="['active']">
            <a class="m1" routerLink="statystyki">Statystyki</a>
          </li>
        </ul>
      </div>
      <div class="px-3 pt-md-1 pr-md-0">
        <div
          class="dropdown person_desktop text-right"
          (click)="pokazMenuKontekstowe()"
          (clickOutside)="kliknijPozaMenu($event)"
        >
          <a
            class="btn__user dropdown-toggle"
            [routerLink]="[]"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- <span class="d-none d-md-block person_name"
              >{{ authService.getUserName() }}
              {{ authService.getUserLastName() }}</span
            > -->
            <span class="inc">{{ pobierzInicjalyUzytkownika() }}</span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuLink"
            style="display: block; z-index: 10000"
            *ngIf="czyPokazacMenuKontekstowe"
          >
            <!-- <a class="dropdown-item ico__account" routerLink="/profile">{{'nawigacja.konto' |
                            translate}}</a>-->
            <a
              class="dropdown-item ico__logout"
              [routerLink]=""
              (click)="wyloguj()"
              >Wyloguj</a
            >
          </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs d-flex d-md-none" id="myTab" role="tablist">
      <li class="nav-item w-50">
        <a
          aria-controls="patient"
          aria-selected="true"
          class="nav-link text-center"
          data-toggle="tab"
          routerLink="konfiguracje-gield"
          id="patient-tab"
          role="tab"
          [routerLinkActive]="['active']"
        >
          Giełdy
        </a>
      </li>
      <li class="nav-item w-50">
        <a
          aria-controls="patient"
          aria-selected="true"
          class="nav-link text-center"
          data-toggle="tab"
          routerLink="konfiguracje-futures"
          id="patient-tab"
          role="tab"
          [routerLinkActive]="['active']"
        >
          Konfiguracje
        </a>
      </li>
      <li class="nav-item w-50">
        <a
          aria-controls="patient"
          aria-selected="true"
          class="nav-link text-center"
          data-toggle="tab"
          routerLink="aktualne-pozycje"
          id="patient-tab"
          role="tab"
          [routerLinkActive]="['active']"
        >
          Aktualne pozycje
        </a>
      </li>
      <li class="nav-item w-50">
        <a
          aria-controls="patient"
          aria-selected="true"
          class="nav-link text-center"
          data-toggle="tab"
          routerLink="historyczne-pozycje"
          id="patient-tab"
          role="tab"
          [routerLinkActive]="['active']"
        >
          Historyczne pozycje
        </a>
      </li>

      <li class="nav-item w-50">
        <a
          aria-controls="patient"
          aria-selected="true"
          class="nav-link text-center"
          data-toggle="tab"
          routerLink="ceny-live"
          id="patient-tab"
          role="tab"
          [routerLinkActive]="['active']"
        >
          Ceny live
        </a>
      </li>
      <li class="nav-item w-50">
        <a
          aria-controls="patient"
          aria-selected="true"
          class="nav-link text-center"
          data-toggle="tab"
          routerLink="testy"
          id="patient-tab"
          role="tab"
          [routerLinkActive]="['active']"
        >
          Testy
        </a>
      </li>
      <li class="nav-item w-50">
        <a
          aria-controls="patient"
          aria-selected="true"
          class="nav-link text-center"
          data-toggle="tab"
          routerLink="statystyki"
          id="patient-tab"
          role="tab"
          [routerLinkActive]="['active']"
        >
          Statystyki
        </a>
      </li>
    </ul>
  </div>
</nav>
