export class PobierzAktualnePozycjeFuturesFiltry {
  cenaSrednia: string;
  rodzajTransakcji: string;
  dataTransakcji: string;
  ilosc: string;
  cenaCalkowitaTransakcji: string;
  gielda: string;
  rodzajKonta: string;
  czyjeKonto: string;
  krypto: string;
  id: string;
  planowanaCenaSprzedazy: string;
  aktualnaCena: string;
}
