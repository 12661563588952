export class PobierzHistorycznePozycjeFuturesFiltry {
  rodzajTransakcji: string;
  ilosc: string;
  cenaCalkowitaTransakcji: string;
  gielda: string;
  rodzajKonta: string;
  czyjeKonto: string;
  cenaZakupu: string;
  cenaSprzedazy: string;
  planowanaCenaSprzedazy: string;
  zarobiono: string;
  dataZakupu: string;
  dataSprzedazy: string;
  procent: string;
  krypto: string;
}
