<div *ngIf="czyZaladowanoDane">
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-4">Konfiguracje giełd</div>
  </div>

  <div class="container-fluid d-none d-md-block pt-4">
    <div class="box__scroll py-3">
      <div
        class="box__scroll--top d-flex justify-content-between align-items-center mb-3"
      >
        <div class="input_container">
          <button
            class="btn__clear--filter"
            (click)="wyczyscFiltry()"
            *ngIf="czyPokazacWyczyscFiltry()"
          >
            Wyczyść filtry
          </button>
        </div>
      </div>
      <div class="tableFixHead tableFixHead--3">
        <table class="table">
          <thead>
            <tr>
              <th>
                Giełda
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('gielda')"
                  (click)="zmienKierunekSortowania('gielda')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.gielda"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.gielda)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.gielda"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.gielda"
                    (click)="
                      this.wyszukiwanie.filtry.gielda = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Rodzaj konta
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('rodzajKonta')"
                  (click)="zmienKierunekSortowania('rodzajKonta')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.rodzajKonta"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.rodzajKonta)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.rodzajKonta"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.rodzajKonta"
                    (click)="
                      this.wyszukiwanie.filtry.rodzajKonta = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>
                Czyje konto
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('czyjeKonto')"
                  (click)="zmienKierunekSortowania('czyjeKonto')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.czyjeKonto"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.czyjeKonto)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.czyjeKonto"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.czyjeKonto"
                    (click)="
                      this.wyszukiwanie.filtry.czyjeKonto = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Czy aktywna
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('czyAktywna')"
                  (click)="zmienKierunekSortowania('czyAktywna')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.czyAktywna"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.czyAktywna)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.czyAktywna"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.czyAktywna"
                    (click)="
                      this.wyszukiwanie.filtry.czyAktywna = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Adres API
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('adres')"
                  (click)="zmienKierunekSortowania('adres')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.adres"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.adres)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.adres"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.adres"
                    (click)="
                      this.wyszukiwanie.filtry.adres = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Budżet
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('budzet')"
                  (click)="zmienKierunekSortowania('budzet')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.budzet"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.budzet)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.budzet"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.budzet"
                    (click)="
                      this.wyszukiwanie.filtry.budzet = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(gieldy)">
            <tr *ngFor="let gielda of gieldy" class="pointer project-row">
              <td>{{ gielda.gielda }}</td>
              <td>{{ gielda.rodzajKonta }}</td>
              <td>{{ gielda.czyjeKonto }}</td>
              <td>{{ gielda.czyAktywna }}</td>
              <td>{{ gielda.adres }}</td>
              <td>{{ gielda.budzet | currency }}</td>
              <td>
                <button class="btn__edit" (click)="edytuj(gielda.id)"></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="ft__03 text-gray text-center my-5 py-5"
          *ngIf="!czySaWynikiNaLiscie(gieldy)"
        >
          <div>Brak giełd dla wybranych filtrów</div>
        </div>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pt-4 pb-2 pt-lg-2 mt-1"
      *ngIf="czySaWynikiNaLiscie(gieldy)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="pb-4 text-right">
      <button class="btn__add" (click)="dodajKonfiguracje()"></button>
    </div>
  </div>

  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">Konfiguracje giełd</div>
    </div>

    <div class="filter-box d-flex justify-content-between pt-3 mb-3">
      <span></span>
      <div class="dropdown person_desktop text-right">
        <a
          class="btn__input--sort dropdown-toggle"
          [routerLink]=""
          (click)="pokazMenuSortowania()"
          role="button"
          id="dropdownSort"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (clickOutside)="czyPokazacMenuSortowanie = false"
        >
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownSort"
          *ngIf="czyPokazacMenuSortowanie"
          style="display: block; position: absolute"
        >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('gielda')"
            >Giełda</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('rodzajKonta')"
            >Rodzaj konta
          </a>
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('czyjeKonto')"
            >Czyje konto</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('czyAktywna')"
            >Czy aktywna</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('adres')"
            >Adres API</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('budzet')"
            >Budżet</a
          >
        </div>
      </div>
    </div>

    <div
      class="filter-select pt-3 pb-2 d-flex flex-column mb-3"
      *ngIf="pobierzKolumneSortowania()"
    >
      <div class="d-flex pb-2">
        <div class="filter_item">
          <button class="btn__x" (click)="wyczyscSortowanieMobile()"></button>
          <span class="fs__sort"
            >Sortowanie: {{ pobierzKolumneSortowania() }}</span
          >
        </div>
        <button
          class="btn__input--sort--bottom ml-3"
          [ngClass]="pobierzKierunekSortowaniaMobile()"
          (click)="zmienKierunekSortowaniaMobile()"
        ></button>
      </div>
    </div>

    <div
      class="input_container mb-3 text-right"
      *ngIf="!czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__search"
        (click)="pokazWyszukiwarkeMobile(true)"
      ></button>
    </div>
    <div
      class="searchBox d-flex flex-wrap por pb-5"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__input--clear"
        (click)="pokazWyszukiwarkeMobile(false)"
      ></button>
    </div>

    <div
      class="box__mobile box__mobile--form mb-3"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Giełda"
          [(ngModel)]="wyszukiwanie.filtry.gielda"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.gielda"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.gielda"
          (click)="this.wyszukiwanie.filtry.gielda = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Rodzaj konta"
          [(ngModel)]="wyszukiwanie.filtry.rodzajKonta"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.rodzajKonta"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.rodzajKonta"
          (click)="this.wyszukiwanie.filtry.rodzajKonta = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Czyje konto"
          [(ngModel)]="wyszukiwanie.filtry.czyjeKonto"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.czyjeKonto"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.czyjeKonto"
          (click)="this.wyszukiwanie.filtry.czyjeKonto = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Czy aktywna"
          [(ngModel)]="wyszukiwanie.filtry.czyAktywna"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.czyAktywna"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.czyAktywna"
          (click)="this.wyszukiwanie.filtry.czyAktywna = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Adres API"
          [(ngModel)]="wyszukiwanie.filtry.adres"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.adres"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.adres"
          (click)="this.wyszukiwanie.filtry.adres = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Budżet"
          [(ngModel)]="wyszukiwanie.filtry.budzet"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.budzet"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.budzet"
          (click)="this.wyszukiwanie.filtry.budzet = ''"
        ></button>
      </div>
    </div>

    <button
      class="btn__border mt-4 mb-4"
      (click)="pobierzDaneDoEkranu(true); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      Szukaj
    </button>

    <button
      class="btn__clear--filter mt-2 mb-4"
      (click)="wyczyscFiltry(); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazacWyczyscFiltry()"
    >
      Wyczyść filtry
    </button>

    <div
      class="no-results text-center ft__no-results"
      *ngIf="!czySaWynikiNaLiscie(gieldy)"
    >
      <div>Brak giełd<br />dla wybranych filtrów</div>
    </div>

    <div class="box__mobile mb-3" *ngFor="let gielda of gieldy">
      <div
        class="d-flex justify-content-between align-items-center pb-2 mb-2 pt-2"
      >
        <span class="fs__pname">Giełda {{ gielda.gielda }}</span>
        <button class="btn__edit" (click)="edytuj(gielda.id)"></button>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Rodzaj konta</span>
        <span class="fs__pname--3 text-lg2">{{ gielda.rodzajKonta }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Czyje konto</span>
        <span class="fs__pname--3 text-lg2">{{ gielda.czyjeKonto }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Czy aktywna</span>
        <span class="fs__pname--3 text-lg2">{{ gielda.czyAktywna }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Adres API</span>
        <span class="fs__pname--3 text-lg2">{{ gielda.adres }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Budżet</span>
        <span class="fs__pname--3 text-lg2">{{
          gielda.budzet | currency
        }}</span>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
      *ngIf="czySaWynikiNaLiscie(gieldy)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="pb-4 text-right">
      <button class="btn__add" (click)="dodajKonfiguracje()"></button>
    </div>
  </div>
</div>
