import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { DodajKonfiguracjeGieldModel } from 'src/app/modele/futures/dodajKonfiguracjeGieldModel';
import { EdytujKonfiguracjeGieldModel } from 'src/app/modele/futures/edytujKonfiguracjeGieldModel';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-edytuj-konfiguracje-gield',
  templateUrl: './edytuj-konfiguracje-gield.component.html',
  styleUrls: ['./edytuj-konfiguracje-gield.component.css'],
})
export class EdytujKonfiguracjeGieldComponent
  extends BazowyComponent
  implements OnInit
{
  model: EdytujKonfiguracjeGieldModel = new EdytujKonfiguracjeGieldModel();
  gieldy: Array<{ value: string; key: string }> = [
    { key: 'BINANCE', value: 'Binance' },
  ];
  adresy: Array<{ value: string; key: string }> = [
    { key: 'https://testnet.binancefuture.com', value: 'Binance TST' },
    { key: 'https://fapi.binance.com', value: 'Binance PRD' },
  ];
  czyjeKonta: Array<{ value: string; key: string }> = [
    { key: 'MSZ', value: 'MSZ' },
    { key: 'KWI', value: 'KWI' },
    { key: 'MKO', value: 'MKO' },
  ];

  constructor(
    public router: Router,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService,
    modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {});
  }

  zapisz() {
    this.futuresSerwis
      .edytujKonfiguracjeGield(this.model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat('Konfiguracja została zapisana');
          this.activeModal.close(true);
          return;
        }
        if (this.bladModelu(rezultat)) {
          this.bledyWalidacji = rezultat.bledyModelu;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }
}
