export class PobierzKonfiguracjeFuturesFiltr {
  gielda: string;
  rodzajKonta: string;
  czyjeKonto: string;
  czyAktywna: string;
  paraKrypto: string;
  dataZakonczenia: string;
  maxGodzinPozycji: string;
  sls: string;
  tps: string;
  sll: string;
  tpl: string;
  dzwignia: string;
  procentKapitalu: string;
  macdSL: string;
  macdLS: string;
  rsiL: string;
  macdSS: string;
  tylkoAnalizaSygnalow: string;
  czyMaAktualnieOtwortaPozycje: string;
  maxWielkoscPozycji: string;
  odkladanyProcentNaKoniecMiesiaca: string;
  odJakiejKwotyOdkladac: string;
}
