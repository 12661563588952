import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DaneZalogowanegoUzytkownika } from '../modele/logowanie/daneZalogowanegoUzytkownika';
import { BladModelu } from '../modele/wspolne/bladModelu';
import { Ciasteczko } from '../modele/wspolne/ciasteczka';
import { Jezyk } from '../modele/wspolne/jezyk';
import { ResponseBazowy } from '../modele/wspolne/responseBazowy';
import { Routing } from '../modele/wspolne/routing';
import { StatusOperacji } from '../modele/wspolne/statusOperacji';
import { Uprawnienie } from '../modele/wspolne/uprawnienie';
import { AuthService } from '../serwisy/ogolne/auth.service';
import { LoaderService } from '../serwisy/ogolne/loader.service';

@Component({
    template: ''
})
export abstract class BazowyComponent {

    public bledyWalidacji: BladModelu[] = new Array();
    public modalReference: NgbModalRef;

    constructor(public authService: AuthService, public modalService: NgbModal, public loaderSerwis:
        LoaderService, private komunikatySerwis: ToastrService, public router: Router) {
    }

    public wyswietlKomunikat(komunikat: string, czyBlad?: boolean) {
        czyBlad = czyBlad == undefined ? false : czyBlad;
        if (komunikat == undefined || komunikat.length == 0) {
            return;
        }
        if (czyBlad) {
            this.komunikatySerwis.error(`<div class="info__error"><div class="container"><div class="info__text bg-center komunikat_error">` + komunikat + `</div></div></div>`); //todo: css z ikoną błędu analogicznie do info__text

        }
        else {
            this.komunikatySerwis.success(`<div class=" info__success"><div class="container"><div class="info__text bg-center komunikat_success">` + komunikat + `</div></div></div>`);

        }
    }

    public wyswietlInformacje(tresc: string) {
        if (tresc == undefined || tresc.length == 0) {
            return;
        }
        this.komunikatySerwis.info(`<div class="container"><div class="info__text">` + tresc + `</div></div>`);
    }


    public czyRezultatOk(rezultat: ResponseBazowy) {

        if (rezultat.czyWylogowac) {
            this.wyczyscCiasteczka();
            this.router.navigate([Routing.Zaloguj]);
            return;
        }

        return rezultat.statusOperacji == StatusOperacji.oK
    }

    public bladModelu(rezultat: ResponseBazowy) {
        return rezultat.statusOperacji == StatusOperacji.bladWalidacji || rezultat.statusOperacji == StatusOperacji.bladWalidacjiLink;
    }

    public ustawCiasteczko(ciasteczko: Ciasteczko, dane: any) {
        this.authService.ustawCiasteczko(ciasteczko, dane);
    }

    public pobierzCiasteczko<T>(ciasteczko: Ciasteczko): T {
        return this.authService.pobierzCiasteczko<T>(ciasteczko);
    }

    public pobierzDaneZalogowanegoUzytkownika() {
        return this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika);
    }

    public czyZalogowany() {
        return this.authService.czyZalogowany();
    }


    public pobierzToken() {
        if (!this.czyZalogowany()) {
            return null;
        }
        var uzytkownik = this.pobierzDaneZalogowanegoUzytkownika();

        return uzytkownik.token;
    }

    public pobierzIdUzytkownika() {
        if (!this.czyZalogowany()) {
            return null;
        }
        var uzytkownik = this.pobierzDaneZalogowanegoUzytkownika();

        return uzytkownik.idUzytkownika;
    }

    public wyczyscCiasteczka() {
        this.authService.wyczyscCiasteczka();
    }

    public czyBladWalidacyjny(wlasciwosc: string) {
        if (this.bledyWalidacji == undefined) {
            return false;
        }
        return this.bledyWalidacji.find(m => m.wlasciwosc.toLowerCase() == wlasciwosc.toLowerCase());
    }

    public czyBladWalidacyjnyListy(wlasciwosc: string, indeks: number) {
        if (this.bledyWalidacji == undefined) {
            return false;
        }
        return this.bledyWalidacji.find(m => m.wlasciwosc.toLowerCase() == wlasciwosc.toLowerCase()
            && m.indeksNaLiscie == indeks);
    }

    // public czyBladWalidacyjnyZLinkiem(wlasciwosc: string) {
    //     if (this.bledyWalidacji == undefined) {
    //         return false;
    //     }
    //     return this.bledyWalidacji.find(m => m.wlasciwosc.toLowerCase() == wlasciwosc.toLowerCase() && m.rodzajLinku != null);
    // }

    public pobierzBladWalidacyjny(wlasciwosc: string) {
        if (this.bledyWalidacji == undefined) {
            return "";
        }
        var blad = this.bledyWalidacji.find(m => m.wlasciwosc.toLowerCase() == wlasciwosc.toLowerCase());

        if (blad == undefined) {
            return "";
        }
        return blad.blad;
    }

    public pobierzBladWalidacyjnyListy(wlasciwosc: string, indeks: number) {
        if (this.bledyWalidacji == undefined) {
            return "";
        }
        var blad = this.bledyWalidacji.find(m => m.wlasciwosc.toLowerCase() == wlasciwosc.toLowerCase()
            && m.indeksNaLiscie == indeks);

        if (blad == undefined) {
            return "";
        }
        return blad.blad;
    }

    public czySaWynikiNaLiscie(listaElementow: any[]) {
        if (listaElementow == null) {
            return false;
        }
        return listaElementow.length != 0;
    }

    public pokazLadowacz() {
        this.loaderSerwis.pokazLadowacz(true);
    }

    public schowajLadowacz() {
        this.loaderSerwis.pokazLadowacz(false);
    }
    public pokazModal(content) {
        this.modalReference = this.modalService.open(content, { centered: true });
    }
    public pokazModalCustom(content, options) {
        this.modalReference = this.modalService.open(content, options);
    }
    public schowjModal() {
        this.modalService.dismissAll();
    }

    public kopiujObiekt<T>(obiekt: any): T {
        return JSON.parse(JSON.stringify(obiekt)) as T;
    }

    foramtujDateTimezone(data: Date) {
        var timeZoneDifference = (data.getTimezoneOffset() / 60) * -1;
        data.setTime(data.getTime() + (timeZoneDifference * 60) * 60 * 1000);
        return data;
    }

    formatujCene(cena: number) {
        if (!cena) {
            return "";
        }
        return cena.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
    }

    // pobierzTlumaczenie(kod: string) {
    //     return this.translate.instant(kod);
    // }

    czyAdmin() {
        return this.authService.pobierzDaneZalogowanegoUzytkownika().uprawnienie == Uprawnienie.administrator;
    }

    ngAfterViewInit(): void {

    }
    inicjujJezyk() {
        //this.translate.use("pl-PL");
        // if (this.authService.isLoggedIn) {
        //     var daneUzytkownika = this.authService.pobierzDaneZalogowanegoUzytkownika();
        //     if (daneUzytkownika.jezyk == Jezyk.pl) {
        //         this.translate.use('pl-PL');
        //     }
        //     if (daneUzytkownika.jezyk == Jezyk.en) {
        //         this.translate.use('en-US');
        //     }
        // }
        // var jezyk = navigator.language;
        // if (jezyk == undefined) {
        //     jezyk = this.translate.getBrowserLang();
        // }

        // if (jezyk == undefined) {
        //     jezyk = "pl-PL";
        // }
        // if (jezyk.toLowerCase().startsWith("en")) {
        //     this.translate.use("en-US");
        // }
        // else {
        //     this.translate.use("pl-PL");
        // }
    }
}