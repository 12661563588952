export class SzukanieNajlepszejKonfiguracjiModel {
  dataOd: string;
  dataDo?: string;
  krypto: string;
  maxGodzinPozycji: number;
  slsOd: number;
  tpsOd: number;
  sllOd: number;
  tplOd: number;
  slsDo: number;
  tpsDo: number;
  sllDo: number;
  tplDo: number;
  slsKrok: number;
  tpsKrok: number;
  sllKrok: number;
  tplKrok: number;
  dzwignia: number;
  procentKapitalu: number;
  macdSLOd: number;
  macdLSOd: number;
  macdSSOd: number;
  macdSLDo: number;
  macdLSDo: number;
  macdSSDo: number;
  macdSLKrok: number;
  macdLSKrok: number;
  macdSSKrok: number;
  rsiL: number;
  maxWielkoscPozycji: number;
  odkladanyProcentNaKoniecMiesiaca?: number;
  odJakiejKwotyOdkladac?: number;
  gieldaId: string;
  budzet: number;
}
